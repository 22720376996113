import { createContext, useEffect, useState } from 'react';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import token from '../abis/token.json';
import TravelstakedWithReward from '../abis/staking.json';
import { ADDRESS_STAKING_POOl1, ADDRESS_STAKING_POOl2, ADDRESS_STAKING_POOl3, ADDRESS_STAKING_POOl4, ADDRESS_STAKING_POOl5, TOKEN_ADDRESS } from '../config/';

export const contextWeb3 = createContext();

const ProviderWeb3 = ({ children }) => {
    const pathname = window.location.pathname;

    const [web3, setWeb3] = useState('undefined');
    const [currentAccount, setCurrentAccount] = useState('undefined');
    const [isReturningUser, setIsReturningUser] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [stakeOf, setStakeOf] = useState(0);
    const [balance, setBalance] = useState(0);

    const checkWalletIsConnected = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            // alert("Por favor conectate con algun proveedor, bien sea metamasks o wallet connect")
            console.log('Make sure you have Metamask installed!');
            return;
        } else {
            console.log("Wallet exists! We're ready to go!");
        }

        const web3 = new Web3(window.ethereum);
        setWeb3(web3);

        const accounts = await ethereum.request({ method: 'eth_accounts' });

        if (accounts.length !== 0) {
            const account = accounts[0];
            console.log('Found an authorized account: ', account);
            handleChangeAccount(accounts);
        } else {
            console.log('No authorized account found');
        }
    };

    const handleButtonMetamask = async () => {
        const { ethereum } = window;

        if (!ethereum) {
            alert('Please install Metamask!');
            return;
        }

        try {
            const web3 = new Web3(window.ethereum);
            setWeb3(web3);
            if (isReturningUser) {
                const walletAddress = await ethereum.request({
                    method: 'eth_requestAccounts',
                    params: [{}]
                });

                await window.ethereum.request({
                    method: 'wallet_requestPermissions',
                    params: [
                        {
                            eth_accounts: {}
                        }
                    ]
                });

                const web3 = new Web3(window.ethereum);
                handleChangeAccount(walletAddress, web3);
                //window.location.reload();
            } else {
                const accounts = await ethereum.request({
                    method: 'eth_requestAccounts'
                });
                console.log('Found an account! Address: ', accounts[0]);
                handleChangeAccount(accounts, web3);
                //window.location.reload();
            }
        } catch (err) {
            console.log(err);
        }
    };

    const logOut = async () => {
        setCurrentAccount('undefined');
        setBalance(0);
        setStakeOf(0);
        document.getElementById('form1').innerHTML = '0.000';
        document.getElementById('form2').innerHTML = '0.000';
        setIsReturningUser(true);
    };

    useEffect(() => {
        //checkWalletIsConnected();
    }, []);

    const handleChangeAccount = async (account, web3) => {
        setBalance(0);
        setStakeOf(0);
        setCurrentAccount(account[0]);

        const contractToken = new web3.eth.Contract(token, TOKEN_ADDRESS);

        const balance = await contractToken.methods.balanceOf(account[0]).call();

        console.log({ balance });

        setBalance(web3.utils.fromWei(balance));

        let ADDRESS_STAKING;
        if (pathname === '/pool1') {
            ADDRESS_STAKING = ADDRESS_STAKING_POOl1;
        }
        if (pathname === '/pool2') {
            ADDRESS_STAKING = ADDRESS_STAKING_POOl2;
        }
        if (pathname === '/pool3') {
            ADDRESS_STAKING = ADDRESS_STAKING_POOl3;
        }
        if (pathname === '/pool4') {
            ADDRESS_STAKING = ADDRESS_STAKING_POOl4;
        }
        if (pathname === '/pool5') {
            ADDRESS_STAKING = ADDRESS_STAKING_POOl5;
        }

        const contractStaking = new web3.eth.Contract(TravelstakedWithReward.abi, ADDRESS_STAKING);

        const stakeOf = await contractStaking.methods.stakeOf(account[0]).call();

        console.log({ stakeOf });

        setStakeOf(stakeOf);
        console.log(' CURRENT ACCOUNT ', account[0]);

        setShowModal(false);
    };

    async function handleButtonWalletConnect() {
        //  Create WalletConnect Provider
        const provider = new WalletConnectProvider({
            rpc: {
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545',
                56: 'https://bsc-dataseed1.binance.org',
                100: 'https://dai.poa.network',
                43113: 'https://api.avax-test.network/ext/bc/C/rpc'
            }
        });

        //  Enable session (triggers QR Code modal)
        await provider.enable();

        //  Create Web3 instance
        const web3 = new Web3(provider);
        setWeb3(web3);

        //  Get Accounts
        const accounts = await web3.eth.getAccounts();
        handleChangeAccount(accounts, web3);
        setShowModal(false);
    }

    return (
        <contextWeb3.Provider
            value={{
                web3,
                handleButtonWalletConnect,
                currentAccount,
                handleButtonMetamask,
                logOut,
                showModal,
                setShowModal,
                balance,
                stakeOf
            }}
        >
            {children}
        </contextWeb3.Provider>
    );
};

export default ProviderWeb3;