import * as React from 'react';

const pool1 = React.lazy(() => import('./Pools/Pool1'));
const pool2 = React.lazy(() => import('./Pools/Pool2'));
const pool3 = React.lazy(() => import('./Pools/Pool3'));
const pool4 = React.lazy(() => import('./Pools/Pool4'));
const pool5 = React.lazy(() => import('./Pools/Pool5'));

const routes = [
    { path: '/pool1', exact: true, name: 'Pool1', component: pool1 },
    { path: '/pool2', exact: true, name: 'Pool2', component: pool2 },
    { path: '/pool3', exact: true, name: 'Pool3', component: pool3 },
    { path: '/pool4', exact: true, name: 'Pool4', component: pool4 },
    { path: '/pool5', exact: true, name: 'Pool5', component: pool5 }
];

export default routes;