import { createContext } from 'react';
import ProviderWeb3 from './contexts/ProviderWeb3';

export const contextProvider = createContext();

const Providers = ({ children }) => {
    return (
        <contextProvider.Provider>
            <ProviderWeb3>{children}</ProviderWeb3>
        </contextProvider.Provider>
    );
};

export default Providers;